import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function WordDrawDesktop() {
  let pathVariants = {
    start: { opacity: 0, pathLength: 0, fill: "#00000000" },
    finished: {
      opacity: 1,
      pathLength: 1,
      fill: "#f3f3f3",

      transition: {
        duration: 1.3,
        ease: "easeOut",
        staggerChildren: 0.15,
        fill: {
          delay: 2,
          duration: 1.25,
          ease: "easeInOut",
        },
      },
    },
  };

  return (
    <div className="logoLinkDiv logoLink mNeg35">
      <Link to="/">
        <motion.svg
          className="logoLink"
          version="1.0"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          width="200px"
          height="200px"
          viewBox="0 0 650 150"
          variants={pathVariants}
          initial="start"
          animate="finished"
          display={{ xs: "none", md: "flex" }}
        >
          <motion.path
            className="cls-1"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m71.5,117.69v-10.62c-1.08,1.5-2.4,2.98-3.94,4.44-1.54,1.46-3.31,2.75-5.31,3.88s-4.27,2.04-6.81,2.75c-2.54.71-5.31,1.06-8.31,1.06s-5.96-.42-8.88-1.25c-2.92-.83-5.54-2.08-7.88-3.75-2.33-1.67-4.23-3.75-5.69-6.25-1.46-2.5-2.19-5.42-2.19-8.75v-.25c0-3.33.69-6.29,2.06-8.88,1.38-2.58,3.27-4.75,5.69-6.5,2.42-1.75,5.33-3.08,8.75-4,3.42-.92,7.21-1.38,11.38-1.38,4.5,0,8.38.25,11.62.75s6.42,1.17,9.5,2v-2.73c0-6.46-1.86-11.31-5.56-14.54-3.71-3.23-8.86-4.85-15.44-4.85-3.92,0-7.5.48-10.75,1.44-3.25.96-6.33,2.19-9.25,3.69l-2.25-5.38c3.5-1.7,7.04-3.06,10.62-4.09,3.58-1.02,7.58-1.53,12-1.53,8.25,0,14.75,2.08,19.5,6.25,4.75,4.17,7.12,10.42,7.12,18.75v39.75h-6Zm.12-31.14c-2.67-.66-5.79-1.31-9.38-1.93-3.58-.62-7.67-.93-12.25-.93-6.75,0-11.94,1.35-15.56,4.05s-5.44,6.29-5.44,10.77v.25c0,2.32.5,4.38,1.5,6.16,1,1.79,2.35,3.3,4.06,4.54,1.71,1.25,3.69,2.2,5.94,2.86s4.58,1,7,1c3.25,0,6.33-.48,9.25-1.43,2.92-.95,5.48-2.28,7.69-3.98,2.21-1.7,3.96-3.76,5.25-6.16,1.29-2.41,1.94-5.06,1.94-7.97v-7.22Z"
          />
          <motion.path
            className="cls-2"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m140.44,108.02c-1.12,2.29-2.69,4.24-4.69,5.86s-4.33,2.87-7,3.74c-2.67.87-5.54,1.31-8.62,1.31-4.67,0-9.33-.87-14-2.62-4.67-1.75-8.71-4-12.12-6.76l3.62-4.88c3.5,2.67,7.17,4.73,11,6.19,3.83,1.46,7.79,2.19,11.88,2.19,4.33,0,7.98-1.08,10.94-3.24,2.96-2.16,4.44-5.12,4.44-8.86v-.25c0-1.83-.46-3.41-1.38-4.74-.92-1.33-2.17-2.49-3.75-3.49-1.58-1-3.38-1.83-5.38-2.49s-4.12-1.33-6.38-2c-2.58-.75-5.19-1.54-7.81-2.37s-4.98-1.89-7.06-3.18c-2.08-1.29-3.77-2.89-5.06-4.8-1.29-1.91-1.94-4.28-1.94-7.11v-.25c0-2.58.52-4.93,1.56-7.05,1.04-2.12,2.48-3.97,4.31-5.55,1.83-1.58,4.04-2.81,6.62-3.68,2.58-.87,5.42-1.31,8.5-1.31,3.83,0,7.77.64,11.81,1.92,4.04,1.28,7.64,3.01,10.81,5.21l-3.25,5.12c-2.92-1.92-6.06-3.46-9.44-4.62-3.38-1.17-6.77-1.75-10.19-1.75-4.42,0-7.94,1.06-10.56,3.18s-3.94,4.76-3.94,7.92v.25c0,1.75.5,3.26,1.5,4.55s2.31,2.39,3.94,3.31,3.52,1.72,5.69,2.43c2.17.71,4.38,1.39,6.62,2.06,2.58.75,5.12,1.56,7.62,2.43s4.75,2,6.75,3.37c2,1.37,3.6,3.02,4.81,4.93,1.21,1.91,1.81,4.28,1.81,7.11v.25c0,2.83-.56,5.38-1.69,7.67Z"
          />
          <motion.path
            className="cls-3"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m187.07,118.25c-1.62.38-3.44.56-5.44.56-2.5,0-4.83-.33-7-1-2.17-.67-4.06-1.73-5.69-3.19-1.62-1.46-2.9-3.29-3.81-5.5-.92-2.21-1.38-4.81-1.38-7.81v-41.75h-9.25v-5.62h9.25v-20.38h6.12v20.38h22.38v5.62h-22.38v41.03c0,4.57,1.19,7.78,3.56,9.6s5.44,2.74,9.19,2.74c1.58,0,3.08-.16,4.5-.47,1.42-.31,3.04-.87,4.88-1.65v5.75c-1.67.75-3.31,1.31-4.94,1.69Z"
          />
          <motion.path
            className="cls-1"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m244.13,59.69c-3.67,0-7.17.69-10.5,2.06-3.33,1.38-6.27,3.42-8.81,6.12-2.54,2.71-4.56,6.04-6.06,10-1.5,3.96-2.25,8.52-2.25,13.69v26.12h-6.12v-63.75h6.12v18.12c1.25-2.83,2.83-5.46,4.75-7.88,1.92-2.42,4.08-4.48,6.5-6.19,2.42-1.71,5.06-3,7.94-3.88s5.89-1.27,9.06-1.19v6.75h-.62Z"
          />
          <motion.path
            className="cls-2"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m315.94,98.56c-1.62,4-3.92,7.54-6.88,10.62-2.96,3.08-6.44,5.52-10.44,7.31s-8.38,2.69-13.12,2.69-9.11-.9-13.06-2.69c-3.96-1.79-7.4-4.19-10.31-7.19-2.92-3-5.19-6.5-6.81-10.5s-2.44-8.25-2.44-12.75v-.25c0-4.5.81-8.75,2.44-12.75s3.92-7.54,6.88-10.62c2.96-3.08,6.44-5.52,10.44-7.31,4-1.79,8.38-2.69,13.12-2.69s9.1.9,13.06,2.69c3.96,1.79,7.39,4.19,10.31,7.19,2.92,3,5.19,6.5,6.81,10.5s2.44,8.25,2.44,12.75v.25c0,4.5-.81,8.75-2.44,12.75Zm-4.06-12.75c0-3.82-.67-7.4-2.01-10.76s-3.2-6.28-5.59-8.77-5.19-4.44-8.41-5.85c-3.22-1.41-6.68-2.12-10.36-2.12s-7.27.73-10.49,2.18c-3.22,1.45-5.99,3.4-8.29,5.85s-4.1,5.33-5.4,8.65-1.95,6.84-1.95,10.58v.25c0,3.82.67,7.4,2.01,10.76s3.2,6.29,5.59,8.77,5.19,4.44,8.42,5.85c3.22,1.41,6.68,2.12,10.36,2.12s7.26-.72,10.49-2.18c3.22-1.45,5.99-3.4,8.29-5.85s4.1-5.33,5.4-8.65,1.95-6.84,1.95-10.58v-.25Z"
          />
          <motion.path
            className="cls-3"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m410.88,91.19c-2.25,5.33-5.44,9.96-9.56,13.88-4.12,3.92-9.04,7-14.75,9.25-5.71,2.25-11.98,3.38-18.81,3.38h-29.12V30.19h29.12c6.83,0,13.1,1.11,18.81,3.31,5.71,2.21,10.62,5.27,14.75,9.19,4.12,3.92,7.31,8.52,9.56,13.81,2.25,5.29,3.38,11.02,3.38,17.19v.25c0,6.17-1.12,11.92-3.38,17.25Zm-3.38-17.25c0-5.18-.94-10.07-2.81-14.67s-4.52-8.61-7.94-12.04c-3.42-3.43-7.58-6.14-12.5-8.15-4.92-2.01-10.42-3.01-16.5-3.01h-22.62v75.75h22.62c6.08,0,11.58-.98,16.5-2.95,4.92-1.96,9.08-4.64,12.5-8.03,3.42-3.39,6.06-7.36,7.94-11.91s2.81-9.47,2.81-14.74v-.25Z"
          />
          <motion.path
            className="cls-1"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m478.75,117.69v-10.62c-1.08,1.5-2.4,2.98-3.94,4.44-1.54,1.46-3.31,2.75-5.31,3.88s-4.27,2.04-6.81,2.75c-2.54.71-5.31,1.06-8.31,1.06s-5.96-.42-8.88-1.25c-2.92-.83-5.54-2.08-7.88-3.75-2.33-1.67-4.23-3.75-5.69-6.25-1.46-2.5-2.19-5.42-2.19-8.75v-.25c0-3.33.69-6.29,2.06-8.88,1.38-2.58,3.27-4.75,5.69-6.5,2.42-1.75,5.33-3.08,8.75-4,3.42-.92,7.21-1.38,11.38-1.38,4.5,0,8.38.25,11.62.75s6.42,1.17,9.5,2v-2.73c0-6.46-1.86-11.31-5.56-14.54-3.71-3.23-8.86-4.85-15.44-4.85-3.92,0-7.5.48-10.75,1.44-3.25.96-6.33,2.19-9.25,3.69l-2.25-5.38c3.5-1.7,7.04-3.06,10.62-4.09,3.58-1.02,7.58-1.53,12-1.53,8.25,0,14.75,2.08,19.5,6.25,4.75,4.17,7.12,10.42,7.12,18.75v39.75h-6Zm.12-31.14c-2.67-.66-5.79-1.31-9.38-1.93-3.58-.62-7.67-.93-12.25-.93-6.75,0-11.94,1.35-15.56,4.05s-5.44,6.29-5.44,10.77v.25c0,2.32.5,4.38,1.5,6.16,1,1.79,2.35,3.3,4.06,4.54,1.71,1.25,3.69,2.2,5.94,2.86s4.58,1,7,1c3.25,0,6.33-.48,9.25-1.43,2.92-.95,5.48-2.28,7.69-3.98,2.21-1.7,3.96-3.76,5.25-6.16,1.29-2.41,1.94-5.06,1.94-7.97v-7.22Z"
          />
          <motion.path
            className="cls-2"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m547.69,108.02c-1.12,2.29-2.69,4.24-4.69,5.86s-4.33,2.87-7,3.74c-2.67.87-5.54,1.31-8.62,1.31-4.67,0-9.33-.87-14-2.62-4.67-1.75-8.71-4-12.12-6.76l3.62-4.88c3.5,2.67,7.17,4.73,11,6.19,3.83,1.46,7.79,2.19,11.88,2.19,4.33,0,7.98-1.08,10.94-3.24,2.96-2.16,4.44-5.12,4.44-8.86v-.25c0-1.83-.46-3.41-1.38-4.74-.92-1.33-2.17-2.49-3.75-3.49-1.58-1-3.38-1.83-5.38-2.49s-4.12-1.33-6.38-2c-2.58-.75-5.19-1.54-7.81-2.37s-4.98-1.89-7.06-3.18c-2.08-1.29-3.77-2.89-5.06-4.8-1.29-1.91-1.94-4.28-1.94-7.11v-.25c0-2.58.52-4.93,1.56-7.05,1.04-2.12,2.48-3.97,4.31-5.55,1.83-1.58,4.04-2.81,6.62-3.68,2.58-.87,5.42-1.31,8.5-1.31,3.83,0,7.77.64,11.81,1.92,4.04,1.28,7.64,3.01,10.81,5.21l-3.25,5.12c-2.92-1.92-6.06-3.46-9.44-4.62-3.38-1.17-6.77-1.75-10.19-1.75-4.42,0-7.94,1.06-10.56,3.18s-3.94,4.76-3.94,7.92v.25c0,1.75.5,3.26,1.5,4.55s2.31,2.39,3.94,3.31,3.52,1.72,5.69,2.43c2.17.71,4.38,1.39,6.62,2.06,2.58.75,5.12,1.56,7.62,2.43s4.75,2,6.75,3.37c2,1.37,3.6,3.02,4.81,4.93,1.21,1.91,1.81,4.28,1.81,7.11v.25c0,2.83-.56,5.38-1.69,7.67Z"
          />
          <motion.path
            className="cls-3"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={pathVariants}
            d="m617.37,117.69v-37.88c0-6.58-1.75-11.85-5.25-15.81-3.5-3.96-8.5-5.94-15-5.94-3.17,0-6.08.56-8.75,1.69-2.67,1.12-5,2.69-7,4.69s-3.56,4.4-4.69,7.19c-1.12,2.79-1.69,5.86-1.69,9.19v36.88h-6.12V26.44h6.12v39.38c2.17-3.75,5.08-6.92,8.75-9.5,3.67-2.58,8.42-3.88,14.25-3.88,4,0,7.58.65,10.75,1.94,3.17,1.29,5.85,3.11,8.06,5.44,2.21,2.33,3.88,5.11,5,8.31,1.12,3.21,1.69,6.73,1.69,10.56v39h-6.12Z"
          />
        </motion.svg>
      </Link>
    </div>
  );
}

export default WordDrawDesktop;
